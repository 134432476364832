[class^="lcc-"] {
    box-sizing: border-box !important;
    font-size: 16px;
    line-height: 22px;
    color: #111;
}
[class^="lcc-"]:first-child {
    margin-top: 0;
}
[class^="lcc-"]:last-child {
    margin-bottom: 0;
}
[class^="lcc-"][inert] {
    pointer-events: none;
    cursor: default;
}
[class^="lcc-"][inert],
[class^="lcc-"][inert] * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.lcc-text {
    font-size: 16px;
    margin: 0 0 22px;
}
.lcc-button {
    display: inline-block;
    background: none;
    background-color: #111;
    color: #fff;
    border: 1px solid #111;
    outline: none;
    font-size: inherit;
    padding: 6px 15px;
    cursor: pointer;
    margin: 1px 0;
    transition: color 0.2s ease, background-color 0.2s ease,
        border-color 0.2s ease;
}
.lcc-button:focus,
.lcc-button:hover {
    background: #777;
    border-color: #777;
}
.lcc-button.lcc-button--link {
    color: inherit;
    background: transparent;
    border-color: transparent;
}
.lcc-button.lcc-button--link:focus,
.lcc-button.lcc-button--link:hover {
    background: #eee;
    border-color: #eee;
}
.lcc-label {
    margin-bottom: 5px;
    display: flex;
    align-items: baseline;
}
.lcc-label[for] {
    cursor: pointer;
}
.lcc-label > * {
    margin-right: 10px;
}
.lcc-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    transition: opacity 0.2s ease-in-out;
}
.lcc-modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
    z-index: 10001;
    padding: 30px;
}
.lcc-modal .lcc-modal__close {
    width: 30px;
    font-size: 24px;
    line-height: 1.25;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: inherit;
    border: none;
    cursor: pointer;
    transition: color 0.2s ease;
}
.lcc-modal .lcc-modal__close:focus,
.lcc-modal .lcc-modal__close:hover {
    color: #777;
}
.lcc-modal .lcc-modal__title {
    font-size: 22px;
    margin-bottom: 1em;
}
.lcc-modal .lcc-modal__section {
    margin-bottom: 20px;
}
.lcc-modal .lcc-modal__actions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.lcc-modal .lcc-modal__actions > * {
    display: block;
    margin-left: 10px;
}
.lcc-modal.lcc-modal--settings {
    z-index: 10002;
}
.lcc-u-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.lcc-u-text-center {
    text-align: center !important;
}

.lcc-u-display-none {
    display: none;
}

[inert] {
    pointer-events: none;
    cursor: default;
}

[inert],
[inert] * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
